.loading
{
  width: 100vw;
  height: 50vh;
  margin-top: 10vh;
  // margin-top: -9vh;
  align-items: center;
  h1 
  {
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
  }
  .imgContainer
  {
    flex-basis: 100%;
    img 
    {
      width: 20vw;
      animation: fadeInOut 4s infinite alternate ease-in-out;
    }
  }
}


@keyframes fadeInOut {
  0% {
      opacity: 0.2;
  }
  50% 
  {
    opacity: 1;
  }
  100% {
      opacity: 0.2;
  }
}