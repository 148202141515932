@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Heading";
  src: url("../public/fonts/Heading.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DM";
  src: url("../public/fonts/DM.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "BebasNeue";
  src: url("../public/fonts/BebasNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
html {
  margin: 0;
  padding: 0;
}
.headingFont {
  font-family: "Heading";
}
.DM {
  font-family: "DM";
}
* {
  font-family: "Poppins";
  transition: 0.5s;
  text-decoration: none;
  box-sizing: border-box;
  color: #23110a;
  list-style: none;
  padding: 0;
  margin: 0;
  scrollbar-width: thin; /* for Firefox */
  scroll-behavior: smooth;
  scrollbar-color: #9c4d02 #ffc8b200; /* for Firefox */
}

/* WebKit Scrollbar */
*::-webkit-scrollbar {
  width: 12px;
}

/* Scrollbar Track */
*::-webkit-scrollbar-track {
  background-color: #ffc8b200;
  border-radius: 10px;
}

/* Scrollbar Thumb */
*::-webkit-scrollbar-thumb {
  background-color: #9c4d02;
  border-radius: 10px;
  border: 3px solid #ffc8b200;
}

/* Scrollbar Corner (for when both horizontal and vertical scrollbars are present) */
*::-webkit-scrollbar-corner {
  background: transparent;
}

/* Hide scrollbar buttons (arrows) */
*::-webkit-scrollbar-button {
  display: none;
}

*::selection {
  background-color: hsla(29, 97%, 31%, 0.45);
  color: #fffff0;
}
Link {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
