@import './styles/variables';
body
{ 
  background-color: white;
}
.App {
  .pageContainer
  {
    margin-top: 9vh;
    min-height: 74vh;
    // background-color: $primaryColor;
  }
  .pagePadding
  {
    padding: 1.5vw;
  }
}
