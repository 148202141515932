@import "../../styles/variables";
.headerDiv {
  position: fixed;
  z-index: 20;
  top: 0;
  margin: 0;
  width: 100vw;
  // height: 10vh;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 1.5vw;
  // padding-bottom: 1vh;
  // padding-top: 1vh;
  background-color: $primaryColor;
  font-size: 1rem;
  .middle {
    flex-basis: 92%;
    // flex-basis: max($numbers: );
    .navBar {
      justify-content: space-between;
      column-gap: 1vw;
      font-family: "BebasNeue";
      font-weight: 700;
      .logoLink {
        width: 3.4vw;
      }

      column-gap: 1vw;
      .navItem {
        padding: 0.5rem;
        border-radius: 0.5rem;
        &:hover {
          background-color: $backgroundColor;
          a {
            color: $primaryColor;
          }
        }
        a {
          color: #d9d9d9;
        }
        &.active {
          background-color: #e8f2e7;
          a {
            color: $primaryColor;
          }
        }
      }
    }
  }
  .right {
    font-family: "BebasNeue";
    font-weight: 700;
    font-size: 1rem;
    flex-basis: 4%;
    align-items: center;
    .instagram {
      img {
        width: 2rem;
        margin-top: .5rem;
      }
    }
  }
  .logoLink {
    flex-basis: 4%;
    .logo {
      width: 3vw;
      background-color: #d9d9d9;
      border-radius: 1rem;
    }
  }
}
